import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [{
    path: '/',
    redirect: '/activity-list'
  },
  {
    path: '/agreement',
    name: 'agreement',
    meta: {
      title: ''
    },
    component: () => import('@/views/user/agreement.vue')
  },
  {
    path: '/pro',
    component: () => import('@/views/layout/children.vue'),
    redirect: '/test',
    children: [{
        path: '/test',
        name: 'test',
        meta: {
          title: '测试'
        },
        component: () => import('@/views/test.vue')
      },
      {
        path: '/honor',
        name: 'honor',
        meta: {
          title: '我的荣誉'
        },
        component: () => import('@/views/user/honor.vue')
      },
      {
        path: '/honordetail',
        name: 'honordetail',
        meta: {
          title: '勋章规则'
        },
        component: () => import('@/views/user/honordetail.vue')
      },
      {
        path: '/faq',
        name: 'faq',
        meta: {
          title: '常见问题'
        },
        component: () => import('@/views/user/faq.vue')
      },
      {
        path: '/evaluate',
        name: 'evaluate',
        meta: {
          title: '自律评价'
        },
        component: () => import('@/views/user/selfevaluate.vue')
      },
      {
        path: '/purse',
        name: 'purse',
        meta: {
          title: '账户营元'
        },
        component: () => import('@/views/user/purse.vue')
      },
      {
        path: '/volunteerservice',
        name: 'volunteerservice',
        meta: {
          title: '志愿服务'
        },
        component: () => import('@/views/user/volunteerservice.vue')
      },
      {
        path: '/servicedetail',
        name: 'servicedetail',
        meta: {
          title: '志愿服务详情'
        },
        component: () => import('@/views/user/servicedetail.vue')
      },
      {
        path: '/activity-detail',
        name: 'activityDetail',
        meta: {
          title: '活动详情'
        },
        component: () => import('@/views/activity/detail.vue')
      },
      {
        path: '/activity-subscribe',
        name: 'activitySubscribe',
        meta: {
          title: '活动预约'
        },
        component: () => import('@/views/activity/subscribemake.vue')
      },
      {
        path: '/orderdetail',
        name: 'orderdetail',
        meta: {
          title: '订单详情'
        },
        component: () => import('@/views/order/detail.vue')
      },
      {
        path: '/orderpay',
        name: 'orderpay',
        meta: {
          title: '收银台'
        },
        component: () => import('@/views/order/pay.vue')
      },
      {
        path: '/ordersuccess',
        name: 'ordersuccess',
        meta: {
          title: '支付成功'
        },
        component: () => import('@/views/order/success.vue')
      },
      {
        path: '/login',
        name: 'login',
        meta: {
          title: '登录'
        },
        component: () => import('@/views/user/login')
      },
      {
        path: '/register',
        name: 'register',
        meta: {
          title: '注册用户'
        },
        component: () => import('@/views/user/register.vue')
      },
      {
        path: '/password',
        name: 'password',
        meta: {
          title: '忘记密码'
        },
        component: () => import('@/views/user/password.vue')
      },
      {
        path: '/help-detail',
        name: 'help-detail',
        meta: {
          title: '帮助详情'
        },
        component: () => import('@/views/notice/detail.vue')
      },
      {
        path: '/orderrefund',
        name: 'orderrefund',
        meta: {
          title: '退款详情'
        },
        component: () => import('@/views/order/refund.vue')
      },
      {
        path: '/person',
        name: 'person',
        meta: {
          title: '个人资料'
        },
        component: () => import('@/views/user/person.vue')
      },
      {
        path: '/recharge',
        name: 'recharge',
        meta: {
          title: '营元充值'
        },
        component: () => import('@/views/user/recharge.vue')
      },
      {
        path: '/phonebind',
        name: 'phonebind',
        meta: {
          title: '手机号绑定'
        },
        component: () => import('@/views/user/phonebind.vue')
      },
      {
        path: '/phoneupdate',
        name: 'phoneupdate',
        meta: {
          title: '更换手机号'
        },
        component: () => import('@/views/user/phoneupdate.vue')
      }
    ]
  }, {
    path: '/',
    component: () => import('@/views/layout/index.vue'),
    redirect: '/activity-list', // 重定向 输入/ 地址会变为/home
    children: [{
        path: '/activity-list',
        name: 'activityList',
        meta: {
          title: '活动列表'
        },
        component: () => import('@/views/activity/index.vue')
      },
      {
        path: '/user',
        name: 'user',
        meta: {
          title: '会员中心'
        },
        component: () => import('@/views/user/index.vue')
      },
      {
        path: '/help',
        name: 'help',
        meta: {
          title: '帮助中心'
        },
        component: () => import('@/views/notice/index.vue')
      },
      {
        path: '/order',
        name: 'order',
        meta: {
          title: '订单列表'
        },
        component: () => import('@/views/order/index.vue')
      },
      {
        path: '/switchover',
        name: 'switchover',
        meta: {
          title: '用户切换'
        },
        component: () => import('@/views/user/switchover.vue')
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: '/',
  routes
})

export default router
