import axios from './request'
export default {
  register(data) {
    return axios({
      name: '注册用户',
      method: 'post',
      url: '/auth-server/app/user/register',
      data
    })
  },
  loginByUsername(data) {
    return axios({
      name: '身份证号密码登录',
      method: 'post',
      url: '/auth-server/app/user/loginByUsername',
      data
    })
  },
  getUserbindList(data) {
    return axios({
      name: '获取可以切换的用户列表',
      method: 'post',
      url: '/system-server/app/sys/user/bindList',
      data,
      headers: {
        'Content-Type': 'multipart/form-date' // 设置请求头
      }
    })
  },
  bindUser(data) {
    return axios({
      name: '绑定用户',
      method: 'post',
      url: '/system-server/app/sys/user/bindUser',
      data
    })
  },
  loginByMobile(data) {
    return axios({
      name: '手机号验证码登录',
      method: 'post',
      url: '/auth-server/app/user/loginByMobile',
      data
    })
  },
  updateUserPassword(data) {
    return axios({
      name: '忘记密码修改密码',
      method: 'post',
      url: '/system-server/app/sys/user/updateUserPassword',
      data
    })
  },
  appLogout(params) {
    return axios({
      name: '退出登录',
      method: 'get',
      url: '/auth-server/app/user/appLogout',
      params
    })
  },
  updateUserInfo(data) {
    return axios({
      name: '修改个人资料',
      method: 'post',
      url: '/system-server/app/sys/user/updateUserInfo',
      data
    })
  },
  updateUserMobile(data) {
    return axios({
      name: '修改绑定的手机号',
      method: 'post',
      url: '/system-server/app/sys/user/updateUserMobile',
      data
    })
  },
  getMobileCode(data) {
    return axios({
      name: '发送验证码',
      method: 'post',
      url: '/business-server/app/sys/sms/getMobileCode',
      data
    })
  },
  getActivityList(data) {
    return axios({
      name: '获取活动列表',
      method: 'post',
      url: '/business-server/business/app/activity/list',
      headers: {
        'Content-Type': 'multipart/form-date' // 设置请求头
      },
      data
    })
  },
  getThemeList(data) {
    return axios({
      name: '获取活动主题数据',
      method: 'post',
      url: '/business-server/business/app/activity/themeList',
      data
    })
  },
  getAppDictValue(params) {
    return axios({
      name: '获取活动筛选栏字典',
      method: 'get',
      url: '/system-server/app/sys/dict/getAppDictValue',
      params
    })
  },
  getQueryActivityById(params) {
    return axios({
      name: '获取活动详情信息',
      method: 'get',
      url: '/business-server/business/app/activity/queryById',
      params
    })
  },
  getHelpCenterById(params) {
    return axios({
      name: '获取帮助中心详情信息',
      method: 'get',
      url: '/business-server/business/message/app/helpCenterById',
      params
    })
  },
  getUserInfoByToken(data) {
    return axios({
      name: '获取当前的用户信息',
      method: 'post',
      url: '/system-server/app/sys/user/getUserInfoByToken',
      data
    })
  },
  getMyHonorList(data) {
    return axios({
      name: '获取勋章列表数据',
      method: 'post',
      url: '/business-server/business/medal/app/getMyHonorList',
      headers: {
        'Content-Type': 'multipart/form-date' // 设置请求头
      },
      data
    })
  },
  getMyMedalList(data) {
    return axios({
      name: '获取争章明细',
      method: 'post',
      url: '/business-server/business/medal/app/getMyMedalList',
      headers: {
        'Content-Type': 'multipart/form-date' // 设置请求头
      },
      data
    })
  },
  orderCreate(data) {
    return axios({
      name: '【活动预约】创建订单',
      method: 'post',
      url: '/business-server/business/app/orderInfo/orderCreate',
      data
    })
  },
  orderPrePay(data) {
    return axios({
      name: '【活动预约】创建预支付订单',
      method: 'post',
      url: '/business-server/business/app/orderInfo/orderPrePay',
      data
    })
  },
  orderModify(data) {
    return axios({
      name: '【活动预约】修改订单信息',
      method: 'post',
      url: '/business-server/business/app/orderInfo/orderModify',
      data
    })
  },
  orderModifyTeam(data) {
    return axios({
      name: '【活动预约】修改队员信息',
      method: 'post',
      url: '/business-server/business/app/orderInfo/orderModifyTeam',
      data
    })
  },
  coinOrderPay(data) {
    return axios({
      name: '营元充值创建订单请求支付',
      method: 'post',
      url: '/business-server/business/app/orderInfo/coinOrderPay',
      data
    })
  },
  helpCenterList(data) {
    return axios({
      name: '获取帮助中心数据',
      method: 'post',
      url: '/business-server/business/message/app/helpCenterList',
      data,
      headers: {
        'Content-Type': 'multipart/form-date' // 设置请求头
      }
    })
  },
  getDisciplineList(data) {
    return axios({
      name: '获取我的自律评价列表',
      method: 'post',
      url: '/business-server/business/evaluate/app/getDisciplineList',
      data,
      headers: {
        'Content-Type': 'multipart/form-date' // 设置请求头
      }
    })
  },
  queryDisciplineById(params) {
    return axios({
      name: '获取评分细则',
      method: 'get',
      url: '/business-server/business/evaluate/app/queryDisciplineById',
      params
    })
  },
  getMyEnrollList(data) {
    return axios({
      name: '获取我的志愿服务',
      method: 'post',
      url: '/business-server/business/volunteer/app/getMyEnrollList',
      data,
      headers: {
        'Content-Type': 'multipart/form-date' // 设置请求头
      }
    })
  },
  getMyEnrollById(params) {
    return axios({
      name: '获取我的志愿服务详情',
      method: 'get',
      url: '/business-server/business/volunteer/app/getMyEnrollById',
      params
    })
  },
  queryScoreByEnrollId(params) {
    return axios({
      name: '获取我的志愿服务评价',
      method: 'get',
      url: '/business-server/business/volunteer/app/queryScoreByEnrollId',
      params
    })
  },
  refundOrder(data) {
    return axios({
      name: '订单申请退款',
      method: 'post',
      url: '/business-server/business/app/orderInfo/refund',
      data
    })
  },
  getMyEnrollTimeTotal(params) {
    return axios({
      name: '获取我的志愿服务时间统计',
      method: 'get',
      url: '/business-server/business/volunteer/app/getMyEnrollTimeTotal',
      params
    })
  },
  getCoinList(data) {
    return axios({
      name: '获取账户营元交易明细',
      method: 'post',
      url: '/business-server/business/coin/app/getCoinList',
      data,
      headers: {
        'Content-Type': 'multipart/form-date' // 设置请求头
      }
    })
  },
  getQuestionList(data) {
    return axios({
      name: '获取常见问题列表数据',
      method: 'post',
      url: '/business-server/business/message/app/questionList',
      data,
      headers: {
        'Content-Type': 'multipart/form-date' // 设置请求头
      }
    })
  },
  getQuestionTypeList(data) {
    return axios({
      name: '获取常见问题类型列表数据',
      method: 'post',
      url: '/business-server/business/message/app/typeList',
      data,
      headers: {
        'Content-Type': 'multipart/form-date' // 设置请求头
      }
    })
  },
  getQuestionByInfo(params) {
    return axios({
      name: '获取常见问题详细数据',
      method: 'get',
      url: '/business-server/business/message/app/questionById',
      params
    })
  },
  queryAboutUsById(params) {
    return axios({
      name: '获取关于我们',
      method: 'get',
      url: '/business-server/business/message/app/queryAboutUsById',
      params
    })
  },
  getOrderList(data) {
    return axios({
      name: '获取线上订单列表',
      method: 'post',
      url: '/business-server/business/app/orderInfo/orderList',
      data,
      headers: {
        'Content-Type': 'multipart/form-date' // 设置请求头
      }
    })
  },
  getQueryOrderById(params) {
    return axios({
      name: '获取线上订单详情',
      method: 'get',
      url: '/business-server/business/app/orderInfo/queryOrderById',
      params
    })
  },
  getCoinTypeList(data) {
    return axios({
      name: '营元兑换列表数据',
      method: 'post',
      url: '/business-server/business/app/orderInfo/coinList',
      data
    })
  },
  queryUserAgreementById(params) {
    return axios({
      name: '获取用户协议',
      method: 'get',
      url: '/business-server/business/message/app/queryUserAgreementById',
      params
    })
  },
  queryPrivacyPolicyById(params) {
    return axios({
      name: '获取隐私政策',
      method: 'get',
      url: '/business-server/business/message/app/queryPrivacyPolicyById',
      params
    })
  }
}
