import axios from 'axios'
import router from '../router/'
import store from '../store'
import {
  Toast
} from 'vant'

// 全局配置
// axios.defaults.headers = {
//   'Content-Type': 'application/json;charset=UTF-8'
// }
// axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';

// 实例配置
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 1000 * 20
})

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    // 在发送请求之前做些什么
    console.log(config)
    // debugger
    Toast.loading({
      message: '加载中...',
      duration: 0,
      forbidClick: true
    })
    console.log('token：' + store.state.data.token)
    if (store.state.data.token) config.headers.token = store.state.data.token

    return config
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    Toast.clear()
    // 对响应数据做点什么
    const {
      data
    } = response
    console.log('response：', response)
    console.log('status：', response.status)
    // if (data.status !== 200) {
    //   if (response.config.headers['Show-Message']) {
    //     Toast.fail(data.msg || '系统错误')
    //   }
    // }
    return data
  },
  (error) => {
    // 响应错误处理
    Toast.clear()
    console.log(error)
    const originalRequest = error.config
    console.log('error：', error.response)
    console.log('status：', error.response.status)

    // 对响应错误做点什么
    // if (error.response) {
    //   const { data } = error.response
    //   Toast.fail(data.msg || '系统错误')
    // }
    // return error.response.data
    if ((error.response.status === 401 || error.response.status === 408 || error.response.status === 403) && !originalRequest._retry) {
      originalRequest._retry = true
      // 这里可以使用Vue Router进行路由跳转
      router.push('/login') // 检测到的异常需要跳转到登录页面
    } else {
      Toast.fail(error.response.msg)
    }
    return Promise.reject(error)
  }
)

export default instance
